// extracted by mini-css-extract-plugin
export var discoverWorksItem = "cS_vC";
export var discoverWorksItem__arrowLink = "cS_vQ";
export var discoverWorksItem__bg = "cS_vG";
export var discoverWorksItem__clientImg = "cS_vT";
export var discoverWorksItem__clientName = "cS_vV";
export var discoverWorksItem__clientPosition = "cS_vW";
export var discoverWorksItem__gradientAfricar = "cS_v8";
export var discoverWorksItem__gradientBabel = "cS_wc";
export var discoverWorksItem__gradientCaker = "cS_v2";
export var discoverWorksItem__gradientDv = "cS_v7";
export var discoverWorksItem__gradientImpact = "cS_vY";
export var discoverWorksItem__gradientIx = "cS_v6";
export var discoverWorksItem__gradientMalleni = "cS_vZ";
export var discoverWorksItem__gradientPmk = "cS_v4";
export var discoverWorksItem__gradientPmkDesktop = "cS_v5";
export var discoverWorksItem__gradientRew = "cS_vX";
export var discoverWorksItem__gradientTimebot = "cS_wb";
export var discoverWorksItem__gradientTradeaboat = "cS_v0";
export var discoverWorksItem__gradientTradervs = "cS_v1";
export var discoverWorksItem__gradientVaria = "cS_v9";
export var discoverWorksItem__gradientWowner = "cS_v3";
export var discoverWorksItem__header = "cS_vD";
export var discoverWorksItem__imageHover = "cS_vF";
export var discoverWorksItem__mobileImage = "cS_vR";
export var discoverWorksItem__results = "cS_vK";
export var discoverWorksItem__results_head = "cS_vL";
export var discoverWorksItem__results_num = "cS_vM";
export var discoverWorksItem__results_text = "cS_vN";
export var discoverWorksItem__results_textOnly = "cS_vP";
export var discoverWorksItem__review = "cS_vS";
export var discoverWorksItem__title = "cS_vH";
export var discoverWorksItem__wrapper = "cS_vJ";