// extracted by mini-css-extract-plugin
export var sprite3DIcons = "cf_pM";
export var sprite3DIcons__sprite = "cf_pN";
export var sprite3DIcons__sprite3DIcon_1 = "cf_pP";
export var sprite3DIcons__sprite3DIcon_10 = "cf_pZ";
export var sprite3DIcons__sprite3DIcon_11 = "cf_p0";
export var sprite3DIcons__sprite3DIcon_12 = "cf_p1";
export var sprite3DIcons__sprite3DIcon_13 = "cf_p2";
export var sprite3DIcons__sprite3DIcon_14 = "cf_p3";
export var sprite3DIcons__sprite3DIcon_15 = "cf_p4";
export var sprite3DIcons__sprite3DIcon_16 = "cf_p5";
export var sprite3DIcons__sprite3DIcon_17 = "cf_p6";
export var sprite3DIcons__sprite3DIcon_18 = "cf_p7";
export var sprite3DIcons__sprite3DIcon_19 = "cf_p8";
export var sprite3DIcons__sprite3DIcon_2 = "cf_pQ";
export var sprite3DIcons__sprite3DIcon_20 = "cf_p9";
export var sprite3DIcons__sprite3DIcon_21 = "cf_qb";
export var sprite3DIcons__sprite3DIcon_22 = "cf_qc";
export var sprite3DIcons__sprite3DIcon_3 = "cf_pR";
export var sprite3DIcons__sprite3DIcon_4 = "cf_pS";
export var sprite3DIcons__sprite3DIcon_5 = "cf_pT";
export var sprite3DIcons__sprite3DIcon_6 = "cf_pV";
export var sprite3DIcons__sprite3DIcon_7 = "cf_pW";
export var sprite3DIcons__sprite3DIcon_8 = "cf_pX";
export var sprite3DIcons__sprite3DIcon_9 = "cf_pY";